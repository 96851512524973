import { API_ENDPOINT } from "@src/common/config";
import axios from "@src/common/http";
import { Fund, FundFile } from "./types";

export const getFileWithBuffer = (file: FundFile): { name: string; size: number; buffer: File } => {
    const {
        uploadFileInfo: { getRawFile },
        name,
        size,
        ...rest
    } = file;
    return { name, size, buffer: getRawFile() };
};

export const getFileListFromFund = (fund: Fund): FundFile[] => {
    const { id: fundId, children, details } = fund;
    const { files } = details;
    const fundFiles = Object.entries(files).reduce((acc: FundFile[], [field, files]) => {
        const mappedFundFiles =
            files?.map((file: FundFile): FundFile => {
                if (!file.externalId) {
                    const temp = { fundId: fundId, field, docType: file.docType, ...getFileWithBuffer(file) };
                    return temp;
                } else {
                    return file;
                }
            }) ?? [];
        return [...acc, ...mappedFundFiles];
    }, []);
    const childrenFiles = children?.flatMap(child => getFileListFromFund(child)) ?? [];
    return [...fundFiles, ...childrenFiles];
};

export const getFileListFromFunds = (funds: Fund[]): FundFile[] => {
    const files = funds.flatMap(getFileListFromFund);
    return files ?? [];
};

export const uploadFiles = async (rlsWizardId: string, files: FundFile[]): Promise<void> => {
    const filesToUpload = files.map(file => {
        const { buffer, field, fundId, name, size, docType } = file;
        const formData = new FormData();
        formData.append("buffer", buffer);
        formData.append("field", field);
        formData.append("name", name);
        formData.append("fundId", fundId);
        formData.append("docType", docType);
        formData.append("size", size?.toString() ?? "");
        return formData;
    });

    if (filesToUpload.length > 0) {
        await Promise.all(
            filesToUpload.map(formData =>
                axios.patch(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/add-file`, formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }),
            ),
        );
    }
};

export const removeFiles = async (rlsWizardId: string, files: FundFile[]): Promise<void> => {
    if (files.length > 0) {
        await Promise.all(files.map(file => axios.patch(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/remove-file/${file._id}`)));
    }
};
