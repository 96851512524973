import React, { useEffect, useState } from "react";
import { publish } from "pubsub-js";
import { useLazyQuery } from "@apollo/client";
import classNames from "classnames";

import { API_ENDPOINT } from "@src/common/config";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { ERole, PSChannel } from "@src/common/types";
import Page, { Title } from "@components/containers/Page";
import { Button, Card, CardHeader, CardContent, CardFooter, BackToTop, Upload as FileUpload } from "@components/common";

import StatusOverviewReport from "../_components/StatusOverviewReport";
import DocumentLoads from "../_components/DocumentLoads";
import "./FundDocuments.scss";
import { GET_FUND_DOCUMENTS_FOR_COMPANY_ID } from "@src/common/graphql";
import { errors } from "@src/common/errors";

const baseClass = "acl-page-fund-documents";

const FundDocuments: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    const [showReport, setShowReport] = useState(false);
    const { sendNotification } = useNotificationContext();

    const onSuccess = ({ message }): void =>
        sendNotification({
            timeout: 12000,
            type: "success",
            message: `${message}\n\n Your files will be visible in the list in a couple of minutes.`,
        });

    const [getFundDocuments, { loading, error, data }] = useLazyQuery(GET_FUND_DOCUMENTS_FOR_COMPANY_ID, {
        variables: { companyId: appUser.companyId },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
            return;
        }
        getFundDocuments().catch(error => console.error(error));
    }, []);

    const classes: string = classNames(baseClass, !appUser.roles.includes(ERole.DocumentManager) && `${baseClass}--no-upload`);
    const columnClasses: string = classNames(`${baseClass}__static`, !appUser.roles.includes(ERole.DocumentManager) && `${baseClass}__static--show-none`);

    return (
        <Page className={classes}>
            <Title className={`${baseClass}__title`}>Fund Documents</Title>
            <div className={columnClasses}>
                {appUser.roles.includes(ERole.DocumentManager) && (
                    <Card className={`${baseClass}__static__upload`}>
                        <CardHeader className={`${baseClass}__static__header`}>
                            <h3 className={`${baseClass}__static__title`}>Upload</h3>
                        </CardHeader>
                        <CardContent className={`${baseClass}__static__content`}>
                            <FileUpload
                                // url={`${API_ENDPOINT}/portal/fund-document?companyId=${appUser.companyId}`}
                                url={`${API_ENDPOINT}/fund-document?companyId=${appUser.companyId}`}
                                extensions={[".pdf", ".zip"]}
                                dataType="documents"
                                onSuccess={onSuccess}
                                customClass="pdf-zip"
                            />
                        </CardContent>
                    </Card>
                )}

                <Card className={`${baseClass}__static__upload`}>
                    <CardHeader className={`${baseClass}__static__header`}>
                        <h3 className={`${baseClass}__static__title`}>Status Overview Report</h3>
                    </CardHeader>
                    <CardContent className={`${baseClass}__static__content`}>
                        <p>Here you will find the fund document Status Overview Report.</p>
                    </CardContent>
                    <CardFooter className={`${baseClass}__static__footer`}>
                        <Button
                            themeColor="primary"
                            onClick={(): void => {
                                if (!appUser.companyId) {
                                    publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
                                    return;
                                }
                                setShowReport(true);
                            }}
                        >
                            Preview
                        </Button>
                    </CardFooter>
                </Card>
            </div>
            <div className={`${baseClass}__status`}>
                <Card className={`${baseClass}__status__upload`}>
                    <CardHeader className={`${baseClass}__status__header`}>
                        <h3 className={`${baseClass}__status__title`}>Recent Fund Documents</h3>
                    </CardHeader>
                    <CardContent className={`${baseClass}__status__content`}>
                        <DocumentLoads documents={data?.fundDocumentsForCompanyId ?? []} loading={loading} />
                    </CardContent>
                </Card>
            </div>
            {showReport && <StatusOverviewReport onClose={(): void => setShowReport(false)} />}
            <BackToTop />
        </Page>
    );
};

export default FundDocuments;
