import { ApexOptions } from "apexcharts";

export enum Color {
    acolinOrange = "#d89f5a",
    acolinOrangeLight = "#ffbb6a",
    acolinGreen = "#7d8f80",
    acolinGreenLight = "#9fbaa3",
    acolinGreenDark = "#5d8f80",
    black = "#000000",
    tertiary = "#202e42",
    text = "#172b4d",
    success = "#61bfad",
    error = "#e75152",
    warning = "#FFA00F",
    blue = "#2d73f5",
    blueLight = "#80acfe",
}

export type ChartOutputData = {
    series: ApexAxisChartSeries | ApexNonAxisChartSeries;
    options: ApexOptions;
};

export type GraphData = {
    name: string;
    type: "bar" | "area" | "line";
    values: {
        [date: string]: number;
    };
    title?: string;
    style?: {
        color?: string;
        opacity?: number;
        stroke?: number;
        dash?: number;
    };
    group?: string;
};

export type ChartInputData = {
    bars: GraphData[];
    lines?: GraphData[];
};

export type FeeCHF = {
    feeInChf: number;
    ausInChf: number;
};

export type Fee = FeeCHF & {
    retroId: number;
    isin: string;
    fullShareClassName: string;
    subFundName: string;
    fundName: string;
    efamaEfcMainCategory: string;
    cpName: string;
    cpMerlinId: number;
    distributorName: string;
    distributorCountry: string;
    distributorType: string;
    contractType: string;
    investorName: string;
    investorCountry: string;
    startDate: string;
    feeType: string;
    fundGroup: string;
};

export type FeeData = {
    [date: string]: {
        TF: Fee[];
        TFA: Fee[];
        FR: Fee[];
        FRA: Fee[];
    };
};

export const numberToCommaSeparatedThousands = (num: number | string): string => Number(num)?.toLocaleString("en-US", { maximumFractionDigits: 0 });
export const numberToCommaSeparatedThousandsTwoDecimals = (num: number | string): string => Number(num)?.toLocaleString("en-US", { maximumFractionDigits: 2 });

const getFormatter =
    (decimals: number) =>
    (num: number | string): string =>
        Number(num)?.toLocaleString("en-US", { maximumFractionDigits: decimals });

const getFormatterBigNumbers = (num: number | string): string => Number(num)?.toLocaleString("en-US", { compactDisplay: "long" });

export const getSeriesElements = (item: GraphData, dates: string[]): { data: number[]; name: string; color: string; type: string; group: string } => {
    const { values, name, style, type, group } = item;
    const data: number[] = dates.map(date => values[date] ?? 0);
    return { data, name, color: style?.color, type, group };
};

type CustomOptions = {
    stacked?: boolean;
    stackOnlyBar?: boolean;
    barSeriesLabel?: string;
    lineSeriesLabel?: string;
    tickAmount?: number;
    barSeriesDecimals?: number;
    lineSeriesDecimals?: number;
    enableBarLables?: boolean;
    isXAxisDatetime?: boolean;
};

export const getChartOptions = (dates: string[], input: ChartInputData, customOptions?: CustomOptions): ApexOptions => {
    const defaultCustomOptions: CustomOptions = {
        stacked: true,
        //stackOnlyBar: true,
        barSeriesLabel: "Bars",
        lineSeriesLabel: "Lines",
        tickAmount: 10,
        barSeriesDecimals: 0,
        lineSeriesDecimals: 2,
        enableBarLables: false,
        isXAxisDatetime: true,
    };
    const customOptionsToUse = { ...defaultCustomOptions, ...customOptions };

    const { bars, lines } = input;
    const { stacked, stackOnlyBar, barSeriesLabel, lineSeriesLabel, tickAmount, barSeriesDecimals, lineSeriesDecimals, enableBarLables, isXAxisDatetime } =
        customOptionsToUse;

    const data = [...bars, ...lines];

    const width = data.map(item => item.style?.stroke ?? 1);
    const dashArray = data.map(item => item.style?.dash ?? 0);
    const opacity = data.map(item => item?.style?.opacity ?? 1);

    const barValues = bars
        .map(bar => Object.values(bar.values))
        .reduce((acc: { min: number; max: number }[], values: number[]) => {
            if (acc.length === 0) {
                return values.map(value => (value >= 0 ? { min: 0, max: value } : { min: value, max: 0 }));
            } else {
                return acc.map((value, i) =>
                    values[i] >= 0 ? { min: value.min, max: value.max + values[i] } : { min: value.min + values[i], max: value.max },
                );
            }
        }, []);

    const realMaxBarValue = Math.max(...barValues.map(value => value.max));
    const realMinBarValue = Math.min(...barValues.map(value => value.min));

    const [ausValues, averageBpsValues, networkBpsValues] = lines;
    const ausMaxValue = ausValues?.values && Object.values(ausValues?.values)?.length > 0 ? Math.max(...Object.values(ausValues.values)) : 0;
    const averageBpsMaxValue =
        averageBpsValues?.values && Object.values(averageBpsValues?.values)?.length > 0 ? Math.max(...Object.values(averageBpsValues.values)) : 0;
    const networkBpsMaxValue =
        networkBpsValues?.values && Object.values(networkBpsValues?.values)?.length > 0 ? Math.max(...Object.values(networkBpsValues.values)) : 0;

    const maxBpsAxisValue = Math.max(averageBpsMaxValue, networkBpsMaxValue) * 1.1;
    const maxAusAxisValue = ausMaxValue * 1.5;

    const maxBarAxisValue = realMaxBarValue * 1.1;
    const minBarAxisValue = realMinBarValue * 1.1;

    const barYAxis: ApexYAxis[] = bars?.map((bar, i): ApexYAxis => {
        if (i === 0) {
            return {
                seriesName: bar.name,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: Color.acolinGreen,
                },
                labels: {
                    style: {
                        colors: Color.acolinGreen,
                    },
                    formatter: getFormatter(barSeriesDecimals),
                },
                title: {
                    text: barSeriesLabel,
                    style: {
                        color: Color.acolinGreen,
                    },
                },
                tooltip: {
                    enabled: false,
                },
                max: maxBarAxisValue,
                min: minBarAxisValue,
                tickAmount,
                forceNiceScale: true,
            };
        } else {
            return {
                show: false,
                seriesName: bar.name,
                max: maxBarAxisValue,
                min: minBarAxisValue,
                tickAmount,
                forceNiceScale: true,
            };
        }
    });

    const lineYAxis: ApexYAxis[] = lines?.map((line, i): ApexYAxis => {
        if (i === 0) {
            return {
                show: true,
                seriesName: line.name,
                opposite: true,
                max: maxAusAxisValue,
                min: 0,
                tickAmount: 10,
                forceNiceScale: true,
                labels: {
                    style: {
                        colors: line.style?.color ?? Color.acolinGreen,
                    },
                    formatter: getFormatterBigNumbers,
                },
                title: {
                    text: line.title,
                    style: {
                        color: line.style?.color ?? Color.acolinGreen,
                    },
                },
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: line.style?.color ?? Color.acolinGreen,
                },
            };
        } else if (i === lines.length - 1) {
            return {
                seriesName: line.name,
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: line.style?.color ?? Color.acolinGreen,
                },
                labels: {
                    style: {
                        colors: line.style?.color ?? Color.acolinGreen,
                    },
                    formatter: getFormatter(lineSeriesDecimals),
                },
                title: {
                    text: lineSeriesLabel,
                    style: {
                        color: line.style?.color ?? Color.acolinGreen,
                    },
                },
                max: maxBpsAxisValue,
                min: 0,
                tickAmount: 10,
                forceNiceScale: true,
            };
        } else {
            return {
                seriesName: line.name,
                show: false,
                max: maxBpsAxisValue,
                min: 0,
                tickAmount: 10,
                forceNiceScale: true,
            };
        }
    });

    const yaxis = [...barYAxis, ...lineYAxis];
    const xaxis: ApexXAxis = isXAxisDatetime
        ? {
              type: "datetime",
              tickPlacement: "on",
          }
        : {
              type: "category",
              categories: dates,
              tickPlacement: "on",
          };

    const xaxisFormat: string = isXAxisDatetime ? "MMM 'yy" : null;

    const options: ApexOptions = {
        annotations: {
            yaxis: [
                {
                    y: 0,
                    borderColor: "#000",
                    borderWidth: 2,
                    fillColor: "#000",
                    strokeDashArray: 0,
                },
            ],
        },
        chart: {
            type: "bar",
            stacked,
            stackOnlyBar,
            zoom: { enabled: true },
        },
        stroke: {
            width,
            dashArray,
            curve: "smooth",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    total: {
                        enabled: enableBarLables,
                        style: {
                            fontSize: "10px",
                        },
                        formatter: getFormatter(barSeriesDecimals),
                    },
                },
            },
        },
        fill: {
            opacity,
        },
        labels: dates,
        markers: {
            size: 0,
        },
        xaxis,
        yaxis,
        tooltip: {
            shared: true,
            intersect: false,
            x: {
                format: xaxisFormat,
            },
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return getFormatter(Math.max(lineSeriesDecimals, barSeriesDecimals))(y.toFixed(2));
                    }
                    return y;
                },
            },
        },
    };
    return options;
};

export const getSeriesAndOptions = (input: ChartInputData, dates: string[], customOptions?: CustomOptions): ChartOutputData => {
    const { bars, lines } = input;

    const barSeries: ApexAxisChartSeries = bars.map(bar => getSeriesElements(bar, dates));
    const lineSeries: ApexAxisChartSeries = lines.map(line => getSeriesElements(line, dates));

    const series = [...barSeries, ...lineSeries];

    const options = getChartOptions(dates, input, customOptions);
    return { series, options };
};

// Months field can be useful if we decided to make requests for each month, instead of each quarter
export type YearMonthQuarter = {
    year: number;
    month: number;
    quarter: number;
};

export const getQuarterFromMonth = (month: number): number => Math.round((month + 1) / 3);

export const getUTCYearAndMonth = (date: Date): YearMonthQuarter => {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const quarter = getQuarterFromMonth(month);
    return { year, month, quarter };
};

export const getPreviousMonth = (date: YearMonthQuarter): YearMonthQuarter => {
    if (date.month === 1) {
        return { year: date.year - 1, month: 12, quarter: 4 };
    } else {
        const previousMonth = date.month - 1;
        return { year: date.year, month: previousMonth, quarter: getQuarterFromMonth(previousMonth) };
    }
};

export const getNextMonth = (date: YearMonthQuarter): YearMonthQuarter => {
    if (date.month === 12) {
        return { year: date.year + 1, month: 1, quarter: 1 };
    } else {
        const nextMonth = date.month + 1;
        return { year: date.year, month: nextMonth, quarter: getQuarterFromMonth(nextMonth) };
    }
};

export const getYearMonthRange = (start: YearMonthQuarter, end: YearMonthQuarter): YearMonthQuarter[] => {
    if (start.year > end.year) {
        return [];
    }
    if (start.year === end.year && start.month > end.month) {
        return [];
    }

    const newEnd = getPreviousMonth(end);
    return [...getYearMonthRange(start, newEnd), end];
};
