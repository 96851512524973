import React, { ReactNode, useCallback, useMemo } from "react";
import { saveAs } from "file-saver";
import { Workbook } from "exceljs";
import { format } from "date-fns";
import { Option, TableColumnNumberFormat, TableColumnType, TableHeader } from "@src/common/types";

import Button from "@components/common/Button";

type Props<T> = {
    data: Record<string, string | number | Date | Option>[];
    filename: string;
    columns: TableHeader<T>[];
    sheetName?: string;
};

// These default settings can be overriden with columns property
const defaultDateFormat = "yyyy-MM-dd";
const defaultColumnWidth = 50;

// These formats are used for percentages (append "%" and normalize to [0-1] range if falg is passed through columns property)
const percentNumberFormats: TableColumnNumberFormat[] = [TableColumnNumberFormat.PERCENT, TableColumnNumberFormat.PERCENT_TWO_DECIMALS];

const ExcelExportTable = <T extends object>({ data, filename, columns, sheetName = "Export" }: Props<T>): ReactNode => {
    const rows = useMemo(
        () =>
            data.map(item =>
                columns.map(col => {
                    const { field, type, exportNumberFormat, exportShouldNormalizePercent } = col;
                    const value = (item as T)[field];
                    if (type === TableColumnType.DATE) {
                        return value ? new Date(value as number | string | Date) : "";
                    }
                    if (type === TableColumnType.OPTION) {
                        const label = (value as Option)?.label ?? "";
                        return label;
                    }
                    if (type === TableColumnType.NUMBER) {
                        if (value === null || value === undefined || Number.isNaN(value)) {
                            return "";
                        }
                        if (percentNumberFormats.includes(exportNumberFormat) && exportShouldNormalizePercent) {
                            // If PERCENT FORMAT is used value needs to be normalized to [0-1] range, if it's not already (for example [0-100] range is not normalized)
                            // This assumes values for numbers that represent percents are in range [0-1] (or in range [0-100] with passed 'exportShouldNormalizePercent' flag)
                            // If values are not in any of those two ranges, then you have problems with math :)
                            const num = value as number;
                            return num / 100;
                        }
                    }
                    return value;
                }),
            ),
        [data, columns],
    );

    const exportToExcel = useCallback(async () => {
        const workbook = new Workbook();
        const sheet = workbook.addWorksheet(sheetName);

        sheet.properties.defaultColWidth = defaultColumnWidth;
        sheet.addTable({
            name: "table",
            ref: "A1",
            headerRow: true,
            style: {
                theme: "TableStyleMedium11",
                showRowStripes: true,
            },
            columns: columns.map(col => ({ name: col.label, filterButton: true })),
            rows,
        });

        columns.forEach((col, index) => {
            const { exportColumnWidth, exportNumberFormat, exportDateFormat, type } = col;

            // Overriding column width
            if (exportColumnWidth) {
                sheet.columns[index].width = exportColumnWidth;
            }

            if (type === TableColumnType.DATE) {
                // Setting date format
                sheet.columns[index].numFmt = exportDateFormat ?? defaultDateFormat;
            } else if (type === TableColumnType.NUMBER && exportNumberFormat) {
                // Setting number format if needed
                sheet.columns[index].numFmt = exportNumberFormat;
            }
        });

        const buffer = await workbook.xlsx.writeBuffer();

        const excelBlob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const timestamp = format(new Date(), "yyyy-MM-dd'T'HH-mm-ss zzz");

        saveAs(excelBlob, `${filename}_${timestamp}.xlsx`);
    }, [data, columns, filename]);

    return (
        <div>
            <Button themeColor="primary" onClick={exportToExcel}>
                Export
            </Button>
        </div>
    );
};

export default ExcelExportTable;
