import { gql } from "@apollo/client";

// QUERIES

export const GET_NOTIFICATIONS = gql`
    query GetNotifications($companyId: Int!) {
        notifications(companyId: $companyId) {
            id
            subject
            message
            attachmentId
            attachmentName
            overview
            releaseDate
        }
    }
`;

export const GET_NOTIFICATIONS_HOME = gql`
    query GetNotifications($companyId: Int!) {
        notifications(companyId: $companyId) {
            id
            subject
            message
            date
            overview
            releaseDate
            attachmentId
            attachmentName
        }
        releaseNotes {
            id
            subject
            message
            overview
            releaseDate
            attachmentId
            attachmentName
            category
        }
    }
`;
export const GET_RELEASE_NOTES = gql`
    query GetReleaseNotes {
        releaseNotes {
            id
            subject
            message
            overview
            releaseDate
            attachmentId
            attachmentName
            category
        }
    }
`;
export const GET_WP_ARTICLES = gql`
    query {
        wpArticles(limit: 5) {
            news {
                id
                title
                date
                slug
                url
            }
            views {
                id
                title
                date
                slug
                url
            }
            url
        }
    }
`;

export const GET_EMPLOYEES = gql`
    query GetEmployees($companyId: Int!) {
        employees(companyId: $companyId) {
            activated
            email
            firstName
            lastName
            jobTitle
            companyName
            roles {
                code
                name
            }
            statusCode
        }
    }
`;

export const GET_VALIDATION_REPORT = gql`
    query GetValidationReport($loadId: Int!) {
        validationReport(loadId: $loadId) {
            loadInformation {
                loadId
                fileName
                validFrom
                status
                createdBy
                createdDate
                reportType
            }
            errors
            warnings
        }
    }
`;

export const GET_COMMISSION_SETTINGS = gql`
    query GetCommissionSettings($companyId: Int!) {
        commissionSettings(companyId: $companyId) {
            actFrequency
            actFundGroups {
                id
                name
            }
        }
    }
`;

export const GET_COMMISSION_LOADS = gql`
    query GetCommissionLoads($companyId: Int!) {
        loads(companyId: $companyId, type: "commission") {
            id
            createdByUserId
            fileName
            validFrom
            loadDate
            lastUpdate
            status
            statusClass
            formattedStatus
            statusInfo
            hasValidationReport
            fundGroupId
        }
    }
`;

export const GET_FUND_DOCUMENTS_FOR_COMPANY_ID = gql`
    query GetFundDocumentsForCompanyId($companyId: Int!) {
        fundDocumentsForCompanyId(companyId: $companyId) {
            documentId
            providerId
            fileName
            type
            importedDate
            asOfDate
            companyId
            status
            formattedStatus
            statusClass
            statusInfo
            keywords
            countries
            languages
        }
    }
`;

export const GET_REGULATORY_LOADS = gql`
    query GetRegulatoryLoads($companyId: Int!) {
        loads(companyId: $companyId, type: "regulatory") {
            id
            createdByUserId
            fileName
            validFrom
            loadDate
            lastUpdate
            status
            statusClass
            formattedStatus
            statusInfo
            hasValidationReport
            fundGroupId
        }
    }
`;

export const GET_STATIC_LOADS = gql`
    query GetStaticLoads($companyId: Int!) {
        loads(companyId: $companyId, type: "static") {
            id
            createdByUserId
            fileName
            validFrom
            loadDate
            lastUpdate
            status
            statusClass
            formattedStatus
            statusInfo
            hasValidationReport
            fundGroupId
        }
    }
`;

export const GET_FAQS = gql`
    query GetFAQs {
        faqs
    }
`;

export const GET_GLOSSARY = gql`
    query GetGlossary {
        glossary
    }
`;

export const GET_USER_GUIDELINES = gql`
    query GetUserGuidelines {
        userGuidelines
    }
`;

export const GET_VENDORS = gql`
    query GetVendors($companyId: Int!) {
        vendors(companyId: $companyId) {
            name
            active
        }
    }
`;

export const GET_DISTRIBUTION_DASHBOARD = gql`
    query GetDistributionDashboard($dashboard: String, $year: Int, $month: Int, $quarter: Int, $includeDateRange: Boolean) {
        fees(dashboard: $dashboard, year: $year, month: $month, quarter: $quarter, includeDateRange: $includeDateRange)
    }
`;

export const GET_DISTRIBUTION_DASHBOARD_TREND = gql`
    query GetDistributionDashboard($view: String) {
        fees(view: $view)
    }
`;

export const GET_DISTRIBUTION_PARTNERS = gql`
    query GetDistributionPartners($companyId: Int!) {
        distributionPartners(companyId: $companyId) {
            id
            name
            type
            countryCode
            startDate
            isActive
            isContracted
            activeDate
            accountid
            omniId
            acl_companyid
            statecode
            statuscode
            address
            city
            country
            description
            website
            fundDocumentSource
            fundStaticDataSource
            fundNAVDataSource
            accountcategorycode
            category
            acl_liquidityrequirement
            liquidityRequirements
            acl_trackrecordrequirements
            trackRecordRequirements
            acl_investmentssrategies
            investmentStrategies
            acolinNotes
            responsibleCRM {
                internalemailaddress
                fullname
                address1_telephone1
            }
            subDistributionPartnersCount
            subDistributionPartners {
                accountid
                omniId
                acl_companyid
                statecode
                statuscode
                address
                city
                country
                name
            }
        }
        allComplianceDetails {
            risk
            lastDDDate
            omniId
        }
    }
`;

export const GET_ALL_FUNDS = gql`
    query GetAllFunds($companyId: Int!) {
        allFunds(companyId: $companyId) {
            funds {
                id
                parentId
                name
                fundName
                cooperationPartnerId
                managingCompanyId
                fundType
                domicileCountry
                legalForm
                active
            }
            subfunds {
                id
                parentId
                name
                fundName
                cooperationPartnerId
                managingCompanyId
                fundType
                domicileCountry
                legalForm
                active
            }
            shareClasses {
                id
                fundId
                isin
                fullShareClassName
                lifecycle
                acolinRelevant
                nav
                navCurrency
                navDate
                cooperationPartnerId
                name
                navInfo
            }
        }
    }
`;

export const GET_COUNTRY_INFOS = gql`
    query countryInfos {
        countryInfos {
            name
            isoa2code
            investmentOpportunities
            acolinScopeOfServices
            countryFacts
            expectedFees
            investorsAndDistributors
            registration
        }
    }
`;

export const GET_SHARE_CLASS_REGISTRATIONS = gql`
    query GetShareClassRegistrations($isin: String!) {
        shareClassRegistrations(isin: $isin) {
            id
            isin
            legalCH
            marketingCH
            legalDE
            marketingDE
            legalGB
            marketingGB
            legalAT
            marketingAT
            legalBE
            marketingBE
            legalCZ
            marketingCZ
            legalDK
            marketingDK
            legalES
            marketingES
            legalFI
            marketingFI
            legalFR
            marketingFR
            legalHR
            marketingHR
            legalHU
            marketingHU
            legalIE
            marketingIE
            legalIS
            marketingIS
            legalIT
            marketingIT
            legalLI
            marketingLI
            legalLU
            marketingLU
            legalNL
            marketingNL
            legalNO
            marketingNO
            legalPL
            marketingPL
            legalRO
            marketingRO
            legalSE
            marketingSE
            legalSG
            marketingSG
            legalSK
            marketingSK
            legalPT
            marketingPT
        }
    }
`;

export const GET_FUND_DOCUMENTS_FOR_ISIN = gql`
    query GetFundDocumentsForIsin($isin: String!) {
        fundDocumentsForIsin(isin: $isin) {
            docs
            countries
            languages
            types
        }
    }
`;

export const GET_SHARE_CLASS_NAVS = gql`
    query GetShareClassNAVs($isin: String!) {
        shareClassNAVs(isin: $isin) {
            id
            isin
            nav
            navCurrency
            validFrom
            validTo
        }
    }
`;

export const GET_SHARE_CLASS_DETAILS = gql`
    query GetShareClassDetails($isin: String!) {
        shareClassDetails(isin: $isin) {
            id
            fundName
            legalForm
            cooperationPartner
            managingCompany
            fundAdministrator
            custodianBank
            isin
            fullShareClassName
            lifecycle
            launchDate
            shareClassCurrency
            repCH
            repDE
            repGB
            repSG
            nav
            navCurrency
            navDate
            allInFee
            allInFeeDate
            managementFee
            managementFeeDate
            performanceFeeMax
            performanceFeeMaxDate
            redemptionFeeFund
            subscriptionFeeDistributor
            subscriptionFeeFund
            ter
            terDate
            ongoingCharges
            ongoingChargesDate
        }
    }
`;

export const GET_ACOLIN_SERVICES_OWNERS = gql`
    query acolinServicesOwners($services: [String]!) {
        acolinServicesOwners(services: $services) {
            serviceId
            serviceName
            ownerName
            serviceDescription
            ownerEmail
        }
    }
`;
// MUTATIONS

export const SEND_MAIL_MUTATION = gql`
    mutation SendMail($recaptcha: String!, $campaign: Campaign!, $params: JSON!) {
        sendMail(recaptcha: $recaptcha, campaign: $campaign, params: $params) {
            message
        }
    }
`;

export const SEND_INSTRUCTION_MAIL_MUTATION = gql`
    mutation SendInstructionMail($email: String!) {
        sendInstructionMail(email: $email)
    }
`;

export const REQUEST_VENDOR_CHANGES_MUTATION = gql`
    mutation RequestVendorChange($params: JSON!) {
        requestVendorChange(params: $params)
    }
`;

export const UPDATE_USER_INFORMATION = gql`
    mutation UpdateUserInformation($userInfo: UserInformationInput!) {
        updateUserInformation(userInfo: $userInfo) {
            email
            firstName
            lastName
            jobTitle
        }
    }
`;

export const UPDATE_USER_ROLES_MUTATION = gql`
    mutation UpdateUserRoles($email: String!, $roles: [String]!) {
        updateUserRoles(email: $email, roles: $roles)
    }
`;

export const REQUEST_NEW_USER_MUTATION = gql`
    mutation RequestNewUser($newUser: NewUserRequest!) {
        requestNewUser(newUser: $newUser)
    }
`;

export const REQUEST_REMOVE_USER_MUTATION = gql`
    mutation RequestRemoveUser($email: String!, $requestedBy: String!) {
        requestRemoveUser(email: $email, requestedBy: $requestedBy)
    }
`;

export const REQUEST_DEMO_MUTATION = gql`
    mutation RequestDemo {
        requestDemo
    }
`;
