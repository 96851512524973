import React from "react";
import { FallbackProps } from "react-error-boundary";

import { Button, Card, CardContent, CardHeader, CardFooter } from "@components/common";

import Page, { Title } from "@components/containers/Page";
import { EMAIL_SUPPORT_ADDRESS } from "@src/common/consts";
import "./Fallback.scss";

const baseClass = "acl-page-fallback";

const Fallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
    <Page className={baseClass}>
        <header className={`${baseClass}__header`} data-testid="fallback-title">
            <Title className={`${baseClass}__title`}>Ooops!</Title>
        </header>
        <Card className={`${baseClass}__card`}>
            <CardHeader>
                <h3 className={`${baseClass}__card__title`}>
                    `We are unable to process your request at this point.
                    <br />
                    Please try again later throughout the day.
                    <br />
                    If the issue persists, please contact <a href={`mailto:${EMAIL_SUPPORT_ADDRESS}`}>{EMAIL_SUPPORT_ADDRESS}</a>.`
                </h3>
            </CardHeader>
            <CardContent>
                <pre className={`${baseClass}__error`}>{error.toString()}</pre>
            </CardContent>
            <CardFooter>
                <Button onClick={resetErrorBoundary} themeColor="primary">
                    Back to homepage
                </Button>
            </CardFooter>
        </Card>
    </Page>
);

export default Fallback;
