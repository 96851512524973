import React, { useState } from "react";
import { REQUEST_VENDOR_CHANGES_MUTATION } from "@src/common/graphql";
import { useMutation } from "@apollo/client";
import { isEqual } from "lodash";

import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { FieldWrapper } from "@progress/kendo-react-form";

import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { Button, Icon, ResponsiveTable } from "@components/common";

import FormGrid from "./FormGrid/FormGrid";
import { Vendor, SortedVendor } from "../types";

import "./VendorForm.scss";

const baseClass = "acl-vendor-form";
interface IProps {
    vendors: Array<Vendor>;
    onClose(event: DialogCloseEvent): void;
}

export const convertArrayToObject = async (array: Vendor[], key: string): Promise<SortedVendor> => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        const values = { ...item };
        delete values.name;

        return {
            ...obj,
            [item[key]]: values,
        };
    }, initialValue);
};

export const getVendorDifferences = async (vendors: Vendor[], changedVendorsList: Vendor[]): Promise<SortedVendor> => {
    const vendorMap = new Map<string, Vendor>(vendors.map(vendor => [vendor.name, vendor]));
    const result = changedVendorsList.reduce((acc, changedVendor) => {
        if (vendorMap.has(changedVendor.name)) {
            const oldVendor: Vendor = vendorMap.get(changedVendor.name);
            if (!isEqual(oldVendor, changedVendor)) {
                const difference = ["static", "regulatory", "esg"].reduce((acc, key) => {
                    if (oldVendor[key] !== changedVendor[key]) {
                        acc[key] = changedVendor[key];
                    }
                    return acc;
                }, {});
                acc[changedVendor.name] = difference;
            }
        }
        return acc;
    }, {});
    return result;
};

export const composeEmailBody = async (vendors: Vendor[], changedVendorsList: Vendor[], newVendorsList: Vendor[], comment: string): Promise<string> => {
    let message = "";
    const comparedObjects = await getVendorDifferences(vendors, changedVendorsList);
    message += Object.entries(comparedObjects).length > 0 ? "Changes to existing vendors:\n" : "";
    Object.entries(comparedObjects).forEach(([key, value]) => {
        message += `- ${key}:\n`;
        Object.entries(value).forEach(([propName, propValue], i) => (message += `${propValue ? "add " : "remove "} ${propName}\n`));
    });
    message += message ? "\n" : "";
    const processedVendorsList = await convertArrayToObject(newVendorsList, "name");
    message += Object.entries(processedVendorsList).length > 0 ? "Add new vendors:\n" : "";
    Object.entries(processedVendorsList).forEach(([key, value]) => {
        message += `- ${key}${Object.values(value).every(item => item !== true) ? "\n" : ":\n"}`;
        Object.entries(value).forEach(([propName, propValue], i) => (message += `${propValue ? "add " + propName : ""}${propValue ? "\n" : ""}`));
    });
    message += message ? "\n" : "";
    if (comment) {
        message += `User comments:\n${comment}`;
    }

    return message;
};

const VendorForm: React.FC<IProps> = ({ vendors, onClose = (): void => undefined }) => {
    const [comment, setComment] = useState<string>("");
    const [newVendorsList, setNewVendorsList] = useState<Vendor[]>([]);
    const [changedVendorsList, setChangedVendorsList] = useState<Vendor[]>([]);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [appUser, setAppUser] = useAppUserContext();
    const { sendNotification } = useNotificationContext();

    const onCloseDialog = (event: DialogCloseEvent): void => onClose(event);

    const [sendMail] = useMutation(REQUEST_VENDOR_CHANGES_MUTATION, {
        onCompleted: data => {
            console.warn("Email sent.");
            setIsSending(false);
            sendNotification({
                timeout: 12000,
                type: "success",
                message: `Your request was successfully submitted and our team is working on it.\n` + `We will get back to you shortly.`,
            });
            onCloseDialog(null);
        },
        onError: error => {
            console.error("Email sending failed: ", error.message);
            setIsSending(false);
        },
    });

    const handleSubmit = async (): Promise<void> => {
        setIsSending(true);
        const message = await composeEmailBody(vendors, changedVendorsList, newVendorsList, comment);
        const sendMailVariables = {
            params: {
                email: appUser.email,
                Company: appUser.companyName,
                Message: message,
            },
        };

        sendMail({
            variables: sendMailVariables,
        });
    };

    return (
        <Dialog className={`${baseClass}__dialog`} title="Change or Add Vendors" onClose={onCloseDialog}>
            <div className={`${baseClass}__content`}>
                <div className={`${baseClass}__vendors`}>
                    <ResponsiveTable>
                        <FormGrid vendors={vendors} setChangedVendors={setChangedVendorsList} />
                    </ResponsiveTable>
                </div>
                <div className={`${baseClass}__add-vendor-section`}>
                    <ResponsiveTable>
                        <FormGrid vendors={[]} shouldAddVendors setChangedVendors={setNewVendorsList} />
                    </ResponsiveTable>
                </div>
                <div className={`${baseClass}__comment`}>
                    <FieldWrapper>
                        <Label>Add a comment</Label>
                        <TextArea
                            className={`${baseClass}__comment-area`}
                            value={comment}
                            onChange={(e): void => setComment(e.value)}
                            placeholder="Please enter your comment here…"
                        />
                    </FieldWrapper>
                </div>
                <div className={`k-form-buttons ${baseClass}__actions`}>
                    <Button
                        themeColor="primary"
                        onClick={handleSubmit}
                        disabled={(!newVendorsList?.length && isEqual(vendors, changedVendorsList)) || isSending}
                    >
                        {isSending && <Icon name="loading" spacing="right" />}
                        Submit Request
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default VendorForm;
