import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { LoadingPanel, Card, Chip, CardContent, CardHeader } from "@components/common";
import { getPercentage } from "@src/common/util";
import { lifecycleStatusMap } from "@src/common/consts";

import { ShareClassDetails, Isin } from "../types";
import "./Overview.scss";
import { GET_SHARE_CLASS_DETAILS } from "@src/common/graphql";

const baseClass = "acl-share-class-details-overview";

type RowProps = {
    label: string;
    value: React.ReactNode;
    activeLifecycle?: boolean;
};

export const Row: React.FC<RowProps> = ({ label, value, activeLifecycle = false }) => (
    <div className={`${baseClass}__dl-row`}>
        <dt>{label}</dt>
        <dd className={activeLifecycle ? `${baseClass}__active-lifecycle` : ""}>{value}</dd>
    </div>
);

type DetailsProps = {
    isin: Isin;
};

const addStatusComponent = (status: string): React.ReactElement => (
    <Chip
        name={status[0].toUpperCase() + status.substring(1)}
        size="small"
        type={lifecycleStatusMap(status)}
        className={`${baseClass}__status--${lifecycleStatusMap(status)}`}
    />
);

const Details: React.FC<DetailsProps> = ({ isin }) => {
    const [details, setDetails] = useState<ShareClassDetails>(null);

    const { loading, error, data } = useQuery(GET_SHARE_CLASS_DETAILS, {
        variables: { isin },
        fetchPolicy: "cache-first",
        onCompleted: data => {
            setDetails(data?.shareClassDetails?.[0]);
        },
    });

    return (
        <div className={`${baseClass}`}>
            {!details ? (
                <div className={`${baseClass}__loading-wrapper`}>
                    <LoadingPanel />
                </div>
            ) : (
                <div className={`${baseClass}__card-wrapper`}>
                    <Card className={`${baseClass}__card ${baseClass}__card--details`}>
                        <CardHeader>
                            <h3>Details</h3>
                        </CardHeader>
                        <CardContent>
                            <dl>
                                <Row label="Fund name" value={details.fundName} />
                                <Row label="Full Share Class Name" value={details.fullShareClassName} />
                                <Row label="ISIN" value={details.isin} />
                                <Row
                                    label="Lifecycle"
                                    value={addStatusComponent(details.lifecycle)}
                                    activeLifecycle={details.lifecycle === "active" ? true : false}
                                />
                                <Row
                                    label="NAV Value"
                                    value={details.nav ? `${Number(details.nav).toFixed(2)} ${details.navCurrency} (${details.navDate})` : "N/A"}
                                />
                            </dl>
                        </CardContent>
                    </Card>
                    <Card className={`${baseClass}__card ${baseClass}__card--facts`}>
                        <CardHeader>
                            <h3>Key Facts</h3>
                        </CardHeader>
                        <CardContent>
                            <dl>
                                <Row label="Legal Form" value={details.legalForm || "N/A"} />
                                <Row label="Managing Company" value={details.managingCompany || "N/A"} />
                                <Row label="Fund Administrator" value={details.fundAdministrator || "N/A"} />
                                <Row label="Custodian Bank" value={details.custodianBank || "N/A"} />
                            </dl>
                        </CardContent>
                    </Card>
                    <Card className={`${baseClass}__card ${baseClass}__card--representatives`}>
                        <CardHeader>
                            <h3>Representatives</h3>
                        </CardHeader>
                        <CardContent>
                            <dl>
                                <Row label="Switzerland (CH)" value={details.repCH || "N/A"} />
                                <Row label="Germany (DE)" value={details.repDE || "N/A"} />
                                <Row label="Great Britain (GB)" value={details.repGB || "N/A"} />
                                <Row label="Singapore (SG)" value={details.repSG || "N/A"} />
                            </dl>
                        </CardContent>
                    </Card>
                    <Card className={`${baseClass}__card ${baseClass}__card--fees`}>
                        <CardHeader>
                            <h3>Fees</h3>
                        </CardHeader>
                        <CardContent>
                            <dl>
                                <Row
                                    label="Performance Fee"
                                    value={`${details.performanceFeeMax ? getPercentage(details.performanceFeeMax) : "N/A"} (${
                                        details.performanceFeeMaxDate || "N/A"
                                    })`}
                                />
                                <Row
                                    label="Subscription Fee in Favour of Distributor"
                                    value={`${details.subscriptionFeeDistributor ? getPercentage(details.subscriptionFeeDistributor) : "N/A"}`}
                                />
                                <Row
                                    label="Subscription Fee Costs in Favour of Fund"
                                    value={`${details.subscriptionFeeFund ? getPercentage(details.subscriptionFeeFund) : "N/A"}`}
                                />
                                <Row
                                    label="Redemption Fee Costs in Favour of Fund"
                                    value={`${details.redemptionFeeFund ? getPercentage(details.redemptionFeeFund) : "N/A"}`}
                                />
                                <Row
                                    label="Management Fee"
                                    value={`${details.managementFee ? getPercentage(details.managementFee) : "N/A"} (${details.managementFeeDate || "N/A"})`}
                                />
                                <Row
                                    label="All-in Fee"
                                    value={`${details.allInFee ? getPercentage(details.allInFee) : "N/A"} (${details.allInFeeDate || "N/A"})`}
                                />
                                <Row label="Total Expense Ratio" value={`${details.ter ? getPercentage(details.ter) : "N/A"} (${details.terDate || "N/A"})`} />
                                <Row
                                    label="Ongoing Charges"
                                    value={`${details.ongoingCharges ? getPercentage(details.ongoingCharges) : "N/A"} (${details.ongoingChargesDate || "N/A"})`}
                                />
                            </dl>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default Details;
