import { AxiosResponse } from "axios";
import { EMAIL_SUPPORT_ADDRESS } from "@src/common/consts";
export const errors = {
    INVALID_REQUEST: null, //"Invalid HTTP request.",
    INVALID_PAYLOAD: null, //"Some required fields are missing.",
    INVALID_RECAPTCHA: "reCAPTCHA verification error. Please try again.",
    // login
    INVALID_CREDENTIALS: "Wrong user email / password combination provided.",
    UNVERIFIED_EMAIL: "User email address is not verified.",
    VALIDATION_ERROR: "Unable to validate user.",
    INVALID_ROLE: `You don't seem to have any portal rights assigned to you.<br />Please contact your Portal Admin or alternatively our Client Service team at <a href="mailto:crmteam@acolin.com">crmteam@acolin.com</a>.`,
    INVALID_STATUS: `You don't seem to have any portal rights assigned to you.<br />Please contact your Portal Admin or alternatively our Client Service team at <a href="mailto:crmteam@acolin.com">crmteam@acolin.com</a>.`,
    INVALID_COMPANY: `You don't seem to have any portal rights assigned to you.<br />Please contact your Portal Admin or alternatively our Client Service team at <a href="mailto:crmteam@acolin.com">crmteam@acolin.com</a>.`,
    UNKNOWN_USER: "Wrong user email / password combination provided.",
    UNKNOWN_COMPANY: `Please contact your admin to have your company name set with us first before you try to log in.<br />Alternatively, please contact our client service team at <a href="mailto:crmteam@acolin.com">crmteam@acolin.com</a>.`,
    LOGIN_ERROR: "Unknown login error occured.",
    // signup
    USER_EXISTS: "User with this account cannot be registered.",
    TOKEN_CREATION: "Error creating access token.",
    INVALID_PASSWORD: "Password cannot be empty or shorter than 8 characters.",
    SIGNUP_ERROR: "Unknown signup error occured.",
    // resetPassword
    INVALID_TOKEN: "Access token is invalid or expired.",
    INVALID_2FA_TOKEN: "2FA token is invalid or expired.",
    RESET_PASSWORD_ERROR: "Unknown password reset error occured.",
    // verifyEmail
    VERIFY_EMAIL_ERROR: "Unknown email verification error occured.",
    // requestResetPassword
    INVALID_USER: "User with this account is invalid.",
    REQUEST_PASSWORD_ERROR: "Unknown request password error occured.",
    MISSING_COMPANY_ID: `Client Portal setup incomplete. Please contact <a href="mailto:${EMAIL_SUPPORT_ADDRESS}">${EMAIL_SUPPORT_ADDRESS}</a> for further assistance.`,

    CHANGE_PASSWORD_ERROR: "Unknown change password error occured.",
    INTERNAL_SERVER_ERROR: null,
    "2FA_ALREADY_ENABLED": "2FA is already enabled.",
    "2FA_ALREADY_DISABLED": "2FA is already disabled.",
};

export const getErrorMessage = (response: AxiosResponse | string, fallback = ""): string => {
    if (typeof response === "string") {
        return response;
    }
    const { data } = response;
    if (!data.error) {
        return `We are unable to process your request at this point.<br />Please try again later throughout the day.<br/>If the issue persists, please contact <a href=\"mailto:${EMAIL_SUPPORT_ADDRESS}\">${EMAIL_SUPPORT_ADDRESS}</a>.`;
    }

    const { name, message } = data.error;
    const result = name
        ? errors[name] || message
        : fallback ||
          `We are unable to process your request at this point.<br />Please try again later throughout the day.<br/>If the issue persists, please contact <a href=\"mailto:${EMAIL_SUPPORT_ADDRESS}\">${EMAIL_SUPPORT_ADDRESS}</a>.`;

    return result;
};
