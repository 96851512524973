import { ForgotPassword, Login, Logout, ResetPassword, Signup } from "@pages/Authentication";
import Home from "@pages/Home";
import Profile from "./Profile";
import Notifications from "./Notifications";
import UserManagement from "./UserManagement";
import Funds from "./Funds";
import DistributionDashboard from "./DistributionDashboard";
import DistributionNetwork from "./DistributionNetwork";
import DataServices from "./DataServices";
import ShareClassDetails from "./Funds/ShareClassDetails";
import ComplianceDashboard from "./ComplianceDashboard";
import StaticData from "./StaticData";
import RegulatoryData from "./RegulatoryData";
import CommissionData from "./CommissionData";
import FundDocuments from "./FundDocuments";
import PointsOfContact from "./PointsOfContact";
import Glossary from "./Glossary";
import FAQs from "./FAQs";
import UserGuidelines from "./UserGuidelines";
import ReleaseNotes from "./ReleaseNotes";

export {
    // Authentication
    ForgotPassword,
    Login,
    Logout,
    ResetPassword,
    Signup,
    Home,
    Profile,
    Notifications,
    UserManagement,
    Funds,
    DistributionDashboard,
    DistributionNetwork,
    DataServices,
    ShareClassDetails,
    ComplianceDashboard,
    StaticData,
    RegulatoryData,
    CommissionData,
    FundDocuments,
    PointsOfContact,
    Glossary,
    FAQs,
    UserGuidelines,
    ReleaseNotes,
};
