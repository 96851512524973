import axios, {
    AxiosInstance,
    AxiosStatic,
    Method,
    ResponseType,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError,
    AxiosPromise,
    Canceler,
    CancelTokenStatic,
    CancelTokenSource,
} from "axios";
import { publish } from "pubsub-js";
import { API_ENDPOINT } from "@src/common/config";
import { PSChannel } from "@src/common/types";
import { logout } from "@src/common/token";

type CustomInstance = AxiosInstance & Partial<AxiosStatic>;

const instance: CustomInstance = axios.create({
    baseURL: API_ENDPOINT,
    validateStatus: (status: number): boolean => true, //status >= 200 && status < 300,
});

// Request interceptor
instance.interceptors.request.use(
    config => {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
            config.headers.setAuthorization(`Bearer ${authToken}`);
        }
        return config;
    },
    error => {
        console.error("Axios request:", error);
        return Promise.reject(error);
    },
);

// Response interceptor
instance.interceptors.response.use(
    response => {
        const { status } = response;

        if (response.status === 401) {
            localStorage.removeItem("authToken");
            logout();
            return;
        } else if (status === 400 || status > 401) {
            console.log("STATUS: ", status);
            publish(PSChannel.Error, response);
        }

        return response;
    },
    error => {
        console.error("Axios response:", error);
        return Promise.reject(error);
    },
);

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export type { Method };
export type { ResponseType };
export type { AxiosRequestConfig };
export type { AxiosResponse };
export type { AxiosError };
export type { AxiosPromise };
export type { Canceler };
export type { CancelTokenStatic };
export type { CancelTokenSource };

export default instance;
