import React, { useEffect, useState } from "react";
import { publish } from "pubsub-js";
import { useAppUserContext } from "@src/common/Context";
import Page, { Title } from "@components/containers/Page";
import { ContactInfo, Link, Notifications, Articles, Card, CardContent, CardFooter, CardHeader } from "@components/common";

import "./Home.scss";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_NOTIFICATIONS_HOME, GET_WP_ARTICLES } from "@src/common/graphql";
import { errors } from "@src/common/errors";
import { PSChannel } from "@src/common/types";

const baseClass = "acl-home";

const Home: React.FC<{}> = () => {
    const [appUser, _setAppUser] = useAppUserContext();
    // const [notifications, setNotificationsData] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const newsResponse = useQuery(GET_WP_ARTICLES);
    const websiteUrl = `${newsResponse.data?.wpArticles?.url}/news/category`;

    const [getNotifications, { loading: loadingNotifications, error, data: notificationsData }] = useLazyQuery(GET_NOTIFICATIONS_HOME, {
        variables: { companyId: appUser.companyId },
        fetchPolicy: "cache-first",
        onCompleted: notificationsData => {
            const allNotifications = notificationsData?.notifications.map(notification => {
                const { id, subject, message, overview, releaseDate: date, attachmentId, attachmentName } = notification;
                return { id, subject, message, overview, date, attachmentId, attachmentName };
            });
            const allReleaseNotes = notificationsData?.releaseNotes.map(releaseNote => {
                const { id, subject, message, overview, releaseDate: date, attachmentId, attachmentName } = releaseNote;
                return { id, subject, message, overview, date, attachmentId, attachmentName };
            });
            allReleaseNotes.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
            const { id, subject, message, overview, date, attachmentId, attachmentName } = allReleaseNotes[0];
            const newestReleaseNote = { id, subject, message, overview, date, attachmentId, attachmentName, isReleaseNote: true };
            allNotifications.push(newestReleaseNote);
            setNotifications(allNotifications);
        },
    });

    useEffect(() => {
        if (!appUser.companyId) {
            publish(PSChannel.Error, errors.MISSING_COMPANY_ID);
            return;
        }
        getNotifications().catch(error => console.error(error));
    }, []);
    return (
        <Page className={baseClass}>
            <Title className={`${baseClass}__title`}>Client Portal</Title>
            <div className={`${baseClass}__widgets`}>
                <Notifications loading={loadingNotifications} notifications={notifications} compact className={`acl-widget acl-widget--notifications`} />
                {appUser.pointsOfContact.crm ? (
                    <ContactInfo className={`acl-widget acl-widget--contact`} {...appUser.pointsOfContact.crm} />
                ) : (
                    <div className={`acl-widget acl-widget--contact`}></div>
                )}
                <div className={`${baseClass}__articles`}>
                    <Articles loading={newsResponse.loading} data={newsResponse.data?.wpArticles?.news} heading="News" url={`${websiteUrl}/news`} />
                    <Articles loading={newsResponse.loading} data={newsResponse.data?.wpArticles?.views} heading="Views" url={`${websiteUrl}/views`} />
                </div>
                <Card className={`acl-widget acl-widget--questions`}>
                    <CardHeader>
                        <h3 className={`${baseClass}__title`}>Questions?</h3>
                    </CardHeader>
                    <CardContent>
                        <p>We are happy to be here for you and answer your questions.</p>
                        <p>Browse our FAQs or find your Point of Contact.</p>
                    </CardContent>
                    <CardFooter>
                        <Link data-testid="faq-link" to="/faq" type="button" primary>
                            FAQ
                        </Link>
                        <Link to="/points-of-contact" type="button" primary>
                            Points of Contact
                        </Link>
                    </CardFooter>
                </Card>
            </div>
        </Page>
    );
};

export default Home;
