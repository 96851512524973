// Mapping by user status is done on profile and user management page
// [status_name, class_name, user_management_explanation, profile_explanation]
export const userStatusMap = {
    1: ["Active", "success", "This user has no pending changes."],
    2: ["Inactive", "disabled", ""],
    805220000: [
        "Pending Update",
        "warning",
        "This user has requested a profile update that is currently being processed.",
        "Your inquiry to update your profile has been successfully submitted and is currently being processed.",
    ],
    805220001: ["Pending Deletion", "error", "Your inquiry to delete this contact has been successfully submitted and is currently being processed."],
    805220002: ["Pending New User", "warning", "Your request to add a new contact has been successfully submitted and is currently being processed."],
};

export const lifecycleStatusMap = (status: string): "toDo" | "disabled" | "success" | "warning" | "error" => {
    switch (status) {
        case "to be launched":
        case "projected":
        case "offering period":
        case "dormant":
        case "in liquidation":
            return "warning";
        case "terminated":
            return "error";
        case "active":
            return "success";
        default:
            return "toDo";
    }
};
export const EMAIL_SUPPORT_ADDRESS = "helpdesk@acolin.com";
