import classNames from "classnames";
import React from "react";

import styles from "./Chip.module.scss";
import { ChipProps } from "./types";

const Chip: React.FC<ChipProps> = ({ name, type = "warning", className, size = "big", onClick }) => (
    <div className={classNames(styles.chip, styles[type], className)} style={{ padding: size === "big" ? "6px 24px" : "4px 8px" }} onClick={onClick}>
        {name}
    </div>
);

export default Chip;
