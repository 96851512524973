import React, { ReactNode } from "react";

import { Dialog, DialogCloseEvent } from "@progress/kendo-react-dialogs";
import Button from "@components/common/Button";

import "./PromptDialog.scss";
import Icon from "../Icon";

type TEvent = DialogCloseEvent | React.MouseEvent<HTMLElement, MouseEvent>;

const baseClass = "acl-user-dialog";

interface IProps {
    title: string;
    children?: ReactNode;
    text?: string;
    confirmText?: string;
    rejectText?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    onReject(event: TEvent): void;
    onConfirm(event: TEvent): void;
}

const PromptDialog: React.FC<IProps> = ({
    onReject = (): void => null,
    onConfirm = (): void => null,
    title,
    text,
    children,
    confirmText = "Yes",
    rejectText = "No",
    isDisabled,
}) => {
    const onRejectDialog = (event: TEvent): void => onReject(event);

    const onConfirmDialog = (event: TEvent): void => onConfirm(event);

    return (
        <Dialog className={`${baseClass}`} title={title} onClose={onRejectDialog}>
            {text && <p>{text}</p>}
            {children}
            <div className={`k-form-buttons ${baseClass}__actions`}>
                <Button fill="outlined" onClick={onRejectDialog} disabled={isDisabled}>
                    {rejectText}
                </Button>
                <Button themeColor="primary" onClick={onConfirmDialog} disabled={isDisabled}>
                    {isDisabled && <Icon name="loading" spacing="right" />}
                    {confirmText}
                </Button>
            </div>
        </Dialog>
    );
};

export default PromptDialog;
