import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./Button.module.scss";

export interface ButtonProps {
    type?: "button" | "submit" | "reset";
    fill?: "solid" | "outlined";
    themeColor?: "primary" | "secondary";
    disabled?: boolean;
    error?: boolean;
    children?: ReactNode;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Button: React.FC<ButtonProps> = ({
    type = "button",
    themeColor = "primary",
    error = false,
    disabled = false,
    className = null,
    fill = "solid",
    children,
    ...props
}) => {
    const buttonClasses = classNames(styles.acoButton, styles[themeColor], styles[fill], error ? styles.error : "", disabled && styles.disabled, className);

    return (
        <button className={buttonClasses} type={type} disabled={disabled} {...props}>
            {children}
        </button>
    );
};

export default Button;
