import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./FieldWrapper.module.scss";

interface Props {
    children?: ReactNode;
    className?: string;
}

const FieldWrapper: React.FC<Props> = ({ children, className }) => <div className={classNames(styles.fieldWrapper, className)}>{children}</div>;

export default FieldWrapper;
