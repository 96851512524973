import React, { useCallback } from "react";
import { CardContent, Card } from "@components/common/Card";
import { Label } from "@progress/kendo-react-labels";
import { Fund, FundFile, FundType, SingleFund, SingleFundFiles, SubFund, SubFundFiles, UmbrellaFiles, UmbrellaFund } from "@pages/FundRepresentation/types";
import { Chip, DragAndDrop, FieldWrapper } from "@components/common";
import { Input } from "@progress/kendo-react-inputs";
import { API_ENDPOINT } from "@src/common/config";
import axios from "@src/common/http";
import "../UpdateDocuments.scss";

const baseClass = "acl-page-update-documents";

type FundDetailsProps = {
    fund: Fund;
    updateFund: (newFund: Fund) => void;
};

const downloadFile = async (file: FundFile): Promise<any> => {
    const { rlsWizardId, externalId } = file || {};
    if (!rlsWizardId || !externalId) {
        console.error(`Missing file info for download.`);
        return;
    }
    const { data } = await axios.get(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/download-file/${externalId}`);
    const { name, buffer } = data;
    const url = window.URL.createObjectURL(new Blob([new Uint8Array(buffer.data)], { type: buffer?.type }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

const UmbrellaDetails: React.FC<FundDetailsProps> = ({ fund, updateFund }) => {
    const { name, details: fundDetails } = fund;
    const details = fundDetails as UmbrellaFund;
    const { files } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        ucitsAttestationFiles,
    } = files;

    const updateFiles = useCallback(
        (filesKey: keyof UmbrellaFiles, newFiles): void => {
            const newDetails: UmbrellaFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    return (
        <div>
            <Chip name={"Umbrella"} type="disabled" />
            <FieldWrapper>
                <Label>Fund Name</Label>
                <Input disabled type="text" placeholder="Enter Fund Name" value={name} className={`${baseClass}__inputTitle`} />
            </FieldWrapper>
            <div className={`${baseClass}__fileList`}>
                <DragAndDrop
                    docType="PR"
                    label="Latest Prospectus / OM / PPM"
                    files={latestProspectusFiles ?? []}
                    setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="AR"
                    label="Latest Annual Report"
                    files={latestAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="SAR"
                    label="Latest Semi-Annual Report"
                    files={latestSemiAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="AoA"
                    label="Articles of Association"
                    files={articlesOfAssociationFiles ?? []}
                    setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="ASL"
                    label="List of the Authorized Signatories for the Fund and the Fund Provider"
                    files={authorizedSignatoriesFiles ?? []}
                    setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    docType="UCITS-att"
                    label="UCITS Attestation"
                    files={ucitsAttestationFiles ?? []}
                    setFiles={newFiles => updateFiles("ucitsAttestationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
            </div>
        </div>
    );
};

const SingleFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund }) => {
    const { name, details: fundDetails } = fund;
    const details: SingleFund = fundDetails as SingleFund;
    const { files } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        marketingFiles,
        priipsKidsFiles,
        ucitsAttestationFiles,
    } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SingleFundFiles, newFiles) => {
            const newDetails: SingleFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    return (
        <div>
            <Chip name={"Single Fund"} type="disabled" />
            <FieldWrapper>
                <Label>Fund Name</Label>
                <Input disabled type="text" placeholder="Enter Fund Name" value={name} className={`${baseClass}__inputTitle`} />
            </FieldWrapper>
            <DragAndDrop
                docType="PR"
                label="Latest Prospectus / OM / PPM"
                files={latestProspectusFiles ?? []}
                setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="AR"
                label="Latest Annual Report"
                files={latestAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="SAR"
                label="Latest Semi-Annual Report"
                files={latestSemiAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="AoA"
                label="Articles of Association"
                files={articlesOfAssociationFiles ?? []}
                setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="ASL"
                label="List of the Authorized Signatories for the Fund and the Fund Provider"
                files={authorizedSignatoriesFiles ?? []}
                setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="MKTG"
                label="Marketing Documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="PRP"
                label="PRIIPs KIDs"
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="UCITS-att"
                label="UCITS Attestation"
                files={ucitsAttestationFiles ?? []}
                setFiles={newFiles => updateFiles("ucitsAttestationFiles", newFiles)}
                onFileClick={downloadFile}
            />
        </div>
    );
};

const SubFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund }) => {
    const { name, details: fundDetails } = fund;
    const details: SubFund = fundDetails as SubFund;
    const { files } = details;
    const { marketingFiles, priipsKidsFiles, prospectusSupplementFiles } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SubFundFiles, newFiles) => {
            const newDetails: SubFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    return (
        <div>
            <Chip name={"Subfund"} type="disabled" />
            <FieldWrapper>
                <Label>Subfund Name</Label>
                <Input disabled type="text" placeholder="Enter Subfund Name" value={name} className={`${baseClass}__inputTitle`} />
            </FieldWrapper>
            <DragAndDrop
                docType="PR"
                label="Prospectus / Supplement"
                files={prospectusSupplementFiles ?? []}
                setFiles={newFiles => updateFiles("prospectusSupplementFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="MKTG"
                label="Marketing Documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                docType="PRP"
                label="PRIIPs KIDs"
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
        </div>
    );
};

const FundDetails: React.FC<FundDetailsProps> = ({ fund: formData, updateFund: setFormData }) => (
    <Card className={`${baseClass}__overview`}>
        <CardContent>
            {formData?.fundType === FundType.UMBRELLA && <UmbrellaDetails fund={formData} updateFund={setFormData} />}
            {formData?.fundType === FundType.SINGLE_FUND && <SingleFundDetails fund={formData} updateFund={setFormData} />}
            {formData?.fundType === FundType.SUBFUND && <SubFundDetails fund={formData} updateFund={setFormData} />}
        </CardContent>
    </Card>
);
export default FundDetails;
