import React, { useCallback, useMemo, useState } from "react";
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadFileRestrictions, UploadSelectMessageProps } from "@progress/kendo-react-upload";
import { Label } from "@progress/kendo-react-labels";
import Icon from "../Icon";

import "./DragAndDrop.scss";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { FundFile } from "@pages/FundRepresentation/types";

const baseClass = "acl-dragAndDrop";

interface IProps {
    files: FundFile[];
    docType: string;
    setFiles: (newFiles: FundFile[]) => void;
    label?: string;
    multiple?: boolean;
    customClass?: string;
    extensions?: string[];
    minFileSize?: number;
    maxFileSize?: number;
    onFileClick?: (file: FundFile) => Promise<any>;
}

const bytesToString = (bytes: number): string => {
    if (bytes < 1024) {
        return `${bytes} B`;
    }
    const kiloBytes = bytes / 1024;
    if (kiloBytes < 1024) {
        return `${kiloBytes.toFixed(1)} KB`;
    }
    const megaBytes = kiloBytes / 1024;
    if (megaBytes < 1024) {
        return `${megaBytes.toFixed(1)} MB`;
    }
    return "File too large";
};

const SelectMessage: React.ComponentType<UploadSelectMessageProps> = ({ message }) => <div> {message}</div>;

type DeleteFileConfirmation = {
    showModal: boolean;
    fileIndex: number;
};

const initialDeleteFileConfirmation: DeleteFileConfirmation = {
    showModal: false,
    fileIndex: null,
};

const DragAndDrop: React.FC<IProps> = (props: IProps) => {
    const {
        files,
        docType,
        setFiles,
        label,
        extensions = [".pdf", ".docx"],
        customClass = "xlsx",
        minFileSize = 0,
        maxFileSize = 20971520,
        multiple = true,
        onFileClick,
    } = props;
    const [shouldDeleteModal, setShouldDeleteModal] = useState<DeleteFileConfirmation>(initialDeleteFileConfirmation);
    const onAdd = useCallback(
        (event: UploadOnAddEvent): void => {
            const filesGroupedByName = event.newState.reduce((acc: Record<string, UploadFileInfo>, current: UploadFileInfo) => {
                acc[current.name] = current;
                return acc;
            }, {});

            const uniqueFiles = Object.values(filesGroupedByName).map(file => {
                const uploadFileInfo: UploadFileInfo = { ...file, name: file.name.replace(/&amp;/g, "&") };
                const fundFile: FundFile = {
                    ...uploadFileInfo,
                    ...file,
                    uploadFileInfo,
                    docType,
                };
                return fundFile;
            });

            setFiles(multiple ? uniqueFiles : uniqueFiles.slice(0, 1));
        },
        [setFiles],
    );

    const removeFile = useCallback(
        (index: number) => {
            const updatedFiles = files.toSpliced(index, 1);
            setFiles(updatedFiles);
        },
        [files, setFiles],
    );

    const restrictions: UploadFileRestrictions = useMemo(() => {
        const options: UploadFileRestrictions = {
            minFileSize,
            maxFileSize,
        };
        if (extensions) {
            options.allowedExtensions = extensions;
        }
        return options;
    }, [minFileSize, maxFileSize, extensions]);

    const isDisabled = useMemo(() => !multiple && files?.length !== 0, [files, multiple]);

    const mappedFiles = useMemo(() => {
        if (!files) {
            return [];
        }
        return files.map(file => ({ ...file, ...file.uploadFileInfo }));
    }, [files]);

    return (
        <div className={`${baseClass}`} data-testid="drag-and-drop">
            <div className={`${baseClass}__labelGroup`}>
                {label && <Label>{label} </Label>}
                <Upload
                    accept={extensions.join(",")}
                    showFileList={false}
                    className={`${baseClass}__widget ${baseClass + "__" + customClass}`}
                    restrictions={restrictions}
                    files={mappedFiles}
                    multiple={multiple}
                    autoUpload={false}
                    showActionButtons={false}
                    onAdd={onAdd}
                    selectMessageUI={() => <SelectMessage message={`Select ${multiple ? "Files" : "File"} to Upload`} />}
                    disabled={isDisabled}
                />
            </div>
            {files?.length > 0 && (
                <ol>
                    {files?.map((file, index) => {
                        const { name, size, externalId } = file;
                        const canDownloadFile = !!externalId;
                        return (
                            <li style={{ display: "flex", alignContent: "space-between" }} key={index}>
                                <p style={{ cursor: canDownloadFile ? "pointer" : "default" }} onClick={() => onFileClick(file)}>
                                    {name} {size ? `(${bytesToString(size)})` : ""}
                                </p>
                                <Icon
                                    title="Remove file"
                                    name="trash"
                                    spacing="both"
                                    onClick={(): void => setShouldDeleteModal({ fileIndex: index, showModal: true })}
                                />
                            </li>
                        );
                    })}
                </ol>
            )}
            <ConfirmationDialog
                shouldOpen={shouldDeleteModal.showModal}
                onClose={(): void => {
                    setShouldDeleteModal(initialDeleteFileConfirmation);
                }}
                onConfirm={(): void => {
                    removeFile(shouldDeleteModal.fileIndex);
                    setShouldDeleteModal(initialDeleteFileConfirmation);
                }}
            >
                <div className={`${baseClass}__success-message`}>Are you sure that you want to discard this file?</div>
            </ConfirmationDialog>
        </div>
    );
};

export default DragAndDrop;
